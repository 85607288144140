import { auth } from "./auth";
import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/svelte";
import app from "../app.config";

Sentry.init({
  dsn: app.sentry,
  enabled: !!import.meta.env.PROD,
  // ...
});

//logger("prod=", import.meta.env.PROD, "dev=", import.meta.env.DEV);

//import Umami from "@bitprojects/umami-logger-typescript";

// Umami.initialize({
//   baseUrl: "https://umami.is",
//   websiteId: "fa083ada-412d-4557-8ad0-85c285f0052b",
// });

mixpanel.init(app.mixpanel, {
  debug: false,
  track_pageview: false,
  persistence: "localStorage",
});

auth.subscribe(($auth) => {
  mixpanel.identify($auth?.user);
  // intercom?
  if (globalThis.hasOwnProperty("Intercom")) {
    // var data = {
    //   user_id: $auth?.user,
    //   email: $auth?.email,
    //   name: $auth?.name,
    //   created_at: $auth?.created / 1000,
    // };
    // logger("intercom=", data);
    globalThis.Intercom(
      "update",
      $auth?.user
        ? {
          user_id: $auth?.user,
          email: $auth?.email,
          name: $auth?.name,
          created_at: $auth?.created / 1000,
        }
        : {
          user_id: null,
          email: null,
          name: null,
          created_at: null,
        }
    );
  }
  if (globalThis.hasOwnProperty("Rollbar"))
    globalThis.Rollbar.configure({
      payload: {
        person: {
          id: $auth?.user,
          username: $auth?.name,
          email: $auth?.email,
        },
      },
    });
});

export interface TrackData {
  component?: string;
  propertyId?: string;
  propertyName?: string;
  policyTitle?: string;
  placeName?: string;
  unit?: string;
  value?: string;
}

export function event(action: string, data: TrackData) {
  mixpanel.track(action, {
    action,
    ...data,
  });
  Umami.trackEvent(action, {
    action,
    ...data,
  });
}

export function trackPage(data: TrackData) {
  mixpanel.track_pageview(data);
}
