<script lang="ts" context="module">
  const nowutc = instant("PT1M");

  const hour = Temporal.Duration.from({ hours: 1 });

  function durationFriendlyHours(
    value: Temporal.Duration | nullish,
    empty: string = ""
  ): string {
    if (!value) return empty;
    if (indefinite(value)) return empty;
    if (Temporal.Duration.compare(value, hour) === 0) return "1:00 hour";
    return `${Math.floor(value.total("hours"))}:${(value.total("minutes") % 60).toString().padStart(2, "0")} hours`;
    return value.toString();
  }
</script>

<script lang="ts">
  import {
    iso,
    instant,
    TemporalZonedDateTimeInterval,
    midnight,
    datetime,
    indefinite,
  } from "$utils/temporal";
  import {
    PermitValidEffectiveInterval,
    PermitValidFromInterval,
    PermitValidFromString,
    PermitValidToString,
    errored,
    namedIntervals,
    now,
    nowIntervals,
    predefinedIntervals,
    startNowEnabled,
    startNowRequired,
  } from "$components/policy";
  import TemporalIntervalField from "$components/temporal/TemporalIntervalBuilderField.svelte";
  import ValueField from "$components/form/ValueField.svelte";
  import UiFormFieldItem from "$components/ui/FormFieldItem.svelte";
  import Option from "$components/form/Option.svelte";

  import Field from "$components/form/Field.svelte";
  import Select from "$components/form/Select.svelte";
  import { createEventDispatcher, onMount, tick } from "svelte";
  import UiFormFieldList from "$components/ui/FormFieldList.svelte";
  import PolicyPermitComplete from "./PolicyPermitComplete.svelte";
  import PolicyError from "./PolicyError.svelte";
  import Button from "$components/form/Button.svelte";
  import TemporalIntervalNamedField from "$components/temporal/TemporalIntervalNamedField.svelte";
  import TemporalDateField from "$components/temporal/TemporalDateField.svelte";
  import PolicyPermitCalendarStep from "./PolicyPermitValidCalendarStep.svelte";
  import PolicyPermitValidAgendaStep from "./PolicyPermitValidAgendaStep.svelte";

  const name: string = "valid";
  const events = createEventDispatcher<{
    change: typeof values;
  }>();

  export let policy: PermitIssuePolicy;
  export let values: Record<string, ParamValues>;
  //export let name: string = "valid";
  export let error: any | nullish = null;

  let value: PermitValid = PermitValidFromString(policy, values);

  // init the nowenabled
  let nowenabled = startNowEnabled(policy);
  let rightnow = true;
  let fixedtimes = !!policy?.validity.times?.fixed;
  let agenda = !!policy?.validity.times?.slots && !policy.parking;
  let calendar = fixedtimes && !policy?.parking;

  $: fixedtimes = !!policy?.validity.times?.fixed;
  $: agenda = !!policy?.validity.times?.slots && !policy.parking;
  $: calendar = fixedtimes && !policy?.parking;

  $: nowzoned = $nowutc.withTimeZone(policy.timezone);
  $: intervals = predefinedIntervals(policy, nowzoned);
  $: nowintervals = nowIntervals(policy, nowzoned);
  $: singlenow = nowintervals.length === 1 && !intervals.length;
  $: err = errored(error, name);

  $: logger("policy valid step=", policy, values);
  $: value = PermitValidFromString(policy, values);
  $: valid =
    intervals.length == 0 || singlenow || Object.values(value).some(Boolean);

  // have to have a duration selection to handle certain cases
  //let duration: Temporal.Duration | Temporal.DurationLike | string | null =
  //null;

  //$: if (!policy.validity.min.now.enabled) rightnow = false;

  $: nowenabled = startNowEnabled(policy);
  $: nowonly = startNowRequired(policy);

  $: named = namedIntervals(policy, nowzoned);
  $: if (!nowenabled) rightnow = false;

  $: if (!nowintervals.length) rightnow = false;

  // $: logger(
  //   "now?=",
  //   nowenabled,
  //   rightnow,
  //   nowintervals.map((i) => i.toString()),
  //   intervals?.length,
  //   intervals.map((i) => i.toString())
  // );

  $: if (value.start && value.end && !value.duration) rightnow = false;

  // default to the first duration
  $: if (
    nowenabled &&
    rightnow &&
    !singlenow &&
    !value?.duration &&
    nowintervals.length
  )
    change({ duration: nowintervals[0].duration });

  // $: if ((!nowenabled || !rightnow) && intervals?.length === 1) {
  //   change(PermitValidFromInterval(intervals[0]));
  // }

  function single(
    nowenabled: boolean,
    rightnow: boolean,
    intervals: TemporalZonedDateTimeInterval[]
  ) {
    if (nowenabled && rightnow) return;
    if (intervals?.length === 1)
      change(PermitValidFromInterval(policy, intervals[0]));
  }

  // run if any of these change
  $: single(nowenabled, rightnow, intervals);

  // $: if (nowenabled && rightnow && duration) {
  //   change(nowintervals
  //       ?.find(({start, end, duration: duration1}) => {
  //         return (
  //           duration &&
  //           Temporal.Duration.compare(duration, duration1 ?? end.since(start)) === 0
  //         );
  //       })
  //   );
  // }

  function change(updated: PermitValid) {
    logger("PolicyValidField.change", updated, value);
    if (!updated) updated = {};
    //value = updated;
    //await tick();
    logger("calling change event=", PermitValidToString(updated));
    events("change", PermitValidToString(updated));
  }

  // trip off any load changes
  onMount(function () {
    //await tick();
    change(value);
    single(nowenabled, rightnow, intervals);
  });

  $: logger(
    "now intervals=",
    nowintervals.map((i) => i.toString())
  );

  // $: logger(
  //   "policyvalidfield",
  //   policy,
  //   "value=",
  //   value,
  //   "now enabled?=",
  //   nowenabled,
  //   "right now?=",
  //   rightnow,
  //   "now=",
  //   iso(nowzoned),
  //   "intervals=",
  //   intervals.map((i) => i.toString()), //.map(({ start, end }) => [iso(start), iso(end)]),
  //   "nowintervals=",
  //   nowintervals.map(({ start, end }) => [iso(start), iso(end)])
  //   // "start=",
  //   // iso(start),
  //   // "end=",
  //   // iso(end),
  //   // "duration=",
  //   // duration,
  //   // iso(duration ? Temporal.Duration.from(duration) : null)
  // );
</script>

{#if calendar}
  <PolicyPermitCalendarStep
    {policy}
    {values}
    {error}
    on:change={(e) => events("change", e.detail)}
    ><slot /></PolicyPermitCalendarStep
  >
{:else if agenda && !nowonly}
  <PolicyPermitValidAgendaStep
    {policy}
    {values}
    {error}
    on:change={(e) => events("change", e.detail)}
    ><slot /></PolicyPermitValidAgendaStep
  >
{:else}
  {#if policy && !singlenow}
    <UiFormFieldList>
      {#if nowenabled && rightnow}
        <UiFormFieldItem class="interval">
          <!-- <TemporalDateField max="2024-08-30" /> -->
          <Field label="Right now">
            <!-- {#if continuous}
            <time datetime="">Until Revoked</time>
          {:else} -->
            <time datetime={value.duration?.toString() || ""}>
              <dfn>for</dfn>
              <abbr>{durationFriendlyHours(value.duration, "ongoing")}</abbr>
              <Select
                on:change={(e) =>
                  change({ duration: Temporal.Duration.from(e.detail.value) })}
              >
                {#each nowintervals as interval}
                  <Option
                    selected={!!value.duration &&
                      Temporal.Duration.compare(
                        value.duration,
                        interval.duration
                      ) === 0}
                    value={iso(interval.duration)}
                    >{durationFriendlyHours(
                      interval.duration,
                      "Until revoked"
                    )}</Option
                  >
                {/each}
              </Select>
            </time>
            <!-- {/if} -->
            {#if !nowonly}
              <Button type="button" on:click={(e) => (rightnow = false)}
                >later&hellip;</Button
              >
            {/if}
          </Field>
          <PolicyError data={err} />
        </UiFormFieldItem>
      {:else if named.size}
        <UiFormFieldItem class="interval">
          <TemporalIntervalNamedField
            label="For"
            value={value?.start
              ? PermitValidEffectiveInterval(value, nowzoned)
              : null}
            items={named}
            on:change={({ detail }) =>
              change(PermitValidFromInterval(policy, detail))}
          />
          <PolicyError data={err} />
        </UiFormFieldItem>
      {:else if intervals.length === 1}
        <!-- well we only have one interval-->

        <ValueField label="Start"
          ><time datetime={iso(intervals[0].minimum)}
            >{datetime(intervals[0].minimum)}</time
          ></ValueField
        >
        <ValueField label="End"
          ><time datetime={iso(intervals[0].maximum)}
            >{datetime(intervals[0].maximum)}</time
          ></ValueField
        >
      {:else}
        <UiFormFieldItem class="interval">
          <TemporalIntervalField
            items={intervals}
            value={value?.start
              ? PermitValidEffectiveInterval(value, nowzoned)
              : null}
            on:change={({ detail }) =>
              change(PermitValidFromInterval(policy, detail))}
          />
          <PolicyError data={err} />
        </UiFormFieldItem>
      {/if}
    </UiFormFieldList>
  {:else if policy && singlenow && !indefinite(nowintervals[0]?.maximum)}
    <UiFormFieldList>
      <!-- <pre>{nowintervals[0]?.toString()}</pre> -->
      {#each [nowintervals[0]?.minimum, nowintervals[0]?.maximum].filter(Boolean) as dt, i}
        <UiFormFieldItem class="interval">
          <ValueField label={i == 0 ? "Start" : "End"}
            ><time datetime={dt.toString()}
              >{datetime(dt) || "when revoked"}</time
            ></ValueField
          >
        </UiFormFieldItem>
      {/each}
    </UiFormFieldList>
  {/if}
  {#if valid && !err}
    <slot />
  {:else}
    <PolicyPermitComplete {policy} />
  {/if}
{/if}
