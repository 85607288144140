<svelte:options runes={true} />

<script lang="ts">
  import { Vehicle } from ".";
  import { fetchImageVehicle } from "./api";
  let submitting: boolean = $state(false);
  const { onvehicle, class: classname } = $props<{
    onvehicle: (vehicle: Vehicle | null) => void;
  }>();

  async function onchange(e: Event) {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (!file) return;

    submitting = true;

    const value = await fetchImageVehicle(file);

    submitting = false;

    if (!value) return;
    onvehicle?.(value);
  }
</script>

{#if submitting}
  <figure class="activity"></figure>
{:else}
  <input
    class={["vehicle", classname].filter(Boolean).join(" ")}
    type="file"
    accept="image/*"
    {onchange}
  />
{/if}
