<svelte:options runes={true} />

<script lang="ts" context="module">
  import { fetchTenantVehiclePermits } from "./api";
  function history(jwt: Readable<string>): Readable<Vehicles | null> {
    return derived(jwt, ($jwt, set) => {
      logger("jwt=", $jwt);
      if (!$jwt) return set(null);
      fetchTenantVehiclePermits($jwt)
        .then((json) => {
          logger("json=", json);
          var vehicles = Object.values(json.items ?? {}).reduce((acc, item) => {
            if (!item.vehicle) return acc;
            acc[item.vehicle.id] = item.vehicle;
            return acc;
          }, {});
          return {
            type: "vehicles",
            generated: json.generated,
            count: Object.keys(vehicles).length,
            items: vehicles,
          };
        })
        .then(set);
    });
  }
</script>

<script lang="ts">
  import UiFormFieldItem from "$components/ui/FormFieldItem.svelte";
  import UiFormFieldList from "$components/ui/FormFieldList.svelte";
  //import { createEventDispatcher } from "svelte";
  import VehicleField from "$components/vehicle/VehicleField.svelte";
  //import MediaField from "$components/media/MediaField.svelte";
  import PolicyPermitComplete from "./PolicyPermitComplete.svelte";
  import PolicyError from "./PolicyError.svelte";
  import { errored } from ".";
  import { writable, derived, type Readable } from "svelte/store";
  import Info from "$components/util/Info.svelte";

  const name: string = "vehicle";

  let {
    //value,
    policy,
    values,
    error = null,
    onchange,
    children,
  } = $props<{
    //value: Vehicle | string | null | undefined;
    policy: PermitIssuePolicy;
    values: Record<string, string | null>;
    error: any | null;
    onchange: (values: typeof values) => void;
  }>();

  let value: string | Vehicle | null | undefined = $derived(
    values?.[name] || null
  );
  let err = $derived(errored(error, name));

  let request: boolean = $derived(!!policy?.vehicle?.request);
  let required: boolean = $derived(!!policy?.vehicle?.required);
  let property = $derived(policy.property as Property); // need?
  //$: logger("vehiclestep", policy, property);
  let scanning = $derived(true);

  //export let policy: PermitIssuePolicy;
  //export let values: Record<string, string | nullish> = {};
  //let value: Vehicle | string | nullish;
  //export let error: any | nullish = null;
  //export let error: any | nullish;
  const tenantid = writable<string>(null);
  const source = history(tenantid);

  //$: logger("vehiclestep", values.tenant);

  $effect(() => {
    tenantid.set(values.tenant);
  });

  //$: tenantid.set(values.tenant);
  //$: logger("source", $source);

  // const eventing = createEventDispatcher<{
  //   change: typeof values;
  // }>();

  function change(updated: typeof value) {
    logger("vehicle step onvehicle", updated, "calling onchange");
    if (typeof updated === "string") onchange({ [name]: updated });
    else
      onchange({
        [name]: (updated as Vehicle)?.display,
      });
  }
  //$: value = values[name]; // pull from policy
  //$: err = errored(error, name);

  // $: request = !!policy?.vehicle?.request;
  // $: required = !!policy?.vehicle?.required;
  // $: property = policy.property as Property; // need?
  // //$: logger("vehiclestep", policy, property);
  // $: scanning =
  //   ((true || policy?.property?.format === "demo" || property?.format) &&
  //     (policy?.scope?.id ??
  //       policy.scope ??
  //       policy.property?.id ??
  //       property?.id)) ||
  //   null;
</script>

{#if request}
  <UiFormFieldList>
    <UiFormFieldItem>
      <VehicleField
        {scanning}
        {value}
        onchange={change}
        {required}
        source={$source}
      />
      {#if value}
        <Info message="Please double check your vehicle plate/tag" />
        <PolicyError data={err} />
      {/if}
      <!-- <MediaField
        {property}
        {required} /> -->
    </UiFormFieldItem>
  </UiFormFieldList>
{/if}
{#if !err && (!required || value)}
  {@render children?.()}
{:else if required}
  <PolicyPermitComplete {policy} />
{/if}
